function Working() {
    return (
    <div id="working" className="how_it_work pt-50 pb-65">
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="section_title text_center mb-60 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>CARACTERÍSTICAS</h6>
						</div>
						<div className="section_main_title maximum_width_descriptions">
							<h1>Nuestro método</h1>
							<p>Nuestro equipo tecnológico cuenta con una amplia visión estratégica, sobre cómo puede la tecnología mejorar una organización y su arquitectura, aportando un valor diferencial y excepcional. Todo ello, es posible conseguirlo a través de nuestras 4 fases.</p>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-3 col-md-6 col-sm-12">
					<div className="single_it_work mb-4">
						<div className="single_it_work_content pl-2 pr-2">
							<div className="single_it_work_content_list">
								<span>1</span>
							</div>
							<div className="single_work_content_title pb-2" style={{ height: '55px' }}>
								<h4>Ideación y conceptualización</h4>
							</div>
							<div className="single_it_work_content_text pt-1">
								<p>Centramos los objetivos y el desarrollo del proyecto, de manera colaborativa con nuestros clientes, empleando nuestra experiencia ante la complejidad de cada aplicación; creando una simbiosis armoniosa con nuestros clientes. </p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-md-6 col-sm-12">
					<div className="single_it_work mb-4">
						<div className="single_it_work_content pl-2 pr-2">
							<div className="single_it_work_content_list">
								<span>2</span>
							</div>
							<div className="single_work_content_title pb-2" style={{ height: '55px' }}>
								<h4>Desarrollo</h4>
							</div>
							<div className="single_it_work_content_text pt-1">
								<p>Materializamos, a través de código, aquello que se ha conceptualizado en el primer punto, realizando entregas faseadas, midiendo o re-ajustando la dirección del desarrollo. Para ello, creamos procesos que aporten visibilidad y tangibilidad a lo largo de todo el transcurso del proyecto. </p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-md-6 col-sm-12">
					<div className="single_it_work mb-4">
						<div className="single_it_work_content pl-2 pr-2">
							<div className="single_it_work_content_list three">
								<span>3</span>
							</div>
							<div className="single_work_content_title pb-2" style={{ height: '55px' }}>
								<h4>Pruebas e iteraciones</h4>
							</div>
							<div className="single_it_work_content_text pt-1">
								<p>Aplicamos altos estándares de calidad en todos nuestros desarrollos. Aplicamos un sistema de prevención de errores y nos aseguramos, mano a mano con nuestro cliente, de que los posibles fallos o iteraciones han sido correctamente solventados. </p>
							</div>
						</div>
					</div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
					<div className="single_it_work mb-4">
						<div className="single_it_work_content pl-2 pr-2">
							<div className="single_it_work_content_list four">
								<span>4</span>
							</div>
							<div className="single_work_content_title pb-2" style={{ height: '55px' }}>
								<h4>Despliegue y mantenimiento</h4>
							</div>
							<div className="single_it_work_content_text pt-1">
								<p>El despliegue de las aplicaciones es un momento crítico y delicado. Por ello, nos aseguramos de poder otorgar seguridad y robustez a través de nuestro método. Además, ofrecemos un servicio de mantenimiento optimizado, ajustado a las necesidades de cada cliente. </p>
							</div>
						</div>
					</div>
				</div>   
			</div>
		</div>
	</div>
    );
}

export default Working;
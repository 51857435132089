import { handleActiveCampaignSubmit } from 'active-campaign-react';
import { useState } from 'react';
import Modal from '../ContactSubmit/Modal';

function Contact() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [companyURL, setCompanyURL] = useState('');
    const [message, setMessage] = useState('');

    const handleInputChange = (fieldName: string, value: string) => {
        if (value.trim() !== '') {
            document.getElementById(fieldName)?.classList.remove('required-field');
        }
    };

    const activeCampaign = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        const isFormValid =
            firstname.trim() !== '' &&
            lastname.trim() !== '' &&
            companyName.trim() !== '' &&
            phone.trim() !== '' &&
            email.trim() !== '' &&
            companyURL.trim() !== '' &&
            message.trim() !== '';

        if (isFormValid) {
            const data = {
                firstname,
                lastname,
                "field[19]": companyName,
                "field[20]": companyURL,
                email,
                phone,
                "field[21]": message,
                jsonp: true
            };
    
            // console.log("target : ", target);

            handleActiveCampaignSubmit(data, 'onblocks', '26');
            setIsModalOpen(true);
            setIsFormSubmitted(true);

        } else {
            console.log("Formulario no válido"); // Indicar que el formulario no es válido

            const requiredFields: Array<[string, string]> = [
                ['firstname', firstname],
                ['lastname', lastname],
                ['companyName', companyName],
                ['phone', phone],
                ['email', email],
                ['companyURL', companyURL],
                ['message', message],
            ];

            requiredFields.forEach(([fieldName, fieldValue]) => {
                if (fieldValue.trim() === '') {
                    document.getElementById(fieldName)?.classList.add('required-field');
                }
            });
        }        
    };



    return (
    <div id="contact" className="contact_area pt-85 pb-90 div-con-degradado-contacto"  >
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="section_title white text_center mb-60 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>EMAIL</h6>
						</div>
						<div className="section_main_title">
							<h1>Contacta con nosotros</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xl-12">
					<div className="quote_wrapper">
                            <form onSubmit={activeCampaign} id="contact_form">
                                <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form_box mb-30">
                                                <input type="text" name="name" id='firstname' placeholder="Nombre*" onChange={(e) => {
                                                    setFirstname(e.target.value);
                                                    handleInputChange('firstname', e.target.value);
                                                }}
                                                value={isFormSubmitted ? '' : firstname}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form_box mb-30">
                                                <input type="text" name="surname" id='lastname'  placeholder="Apellidos*" onChange={(e) => {
                                                    setLastname(e.target.value);
                                                    handleInputChange('lastname', e.target.value);
                                                }}
                                                value={isFormSubmitted ? '' : lastname}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form_box mb-30">
                                                <input type="text" name="phone" id='phone' placeholder="Número de teléfono*" onChange={(e) => {
                                                    setPhone(e.target.value);
                                                    handleInputChange('phone', e.target.value);
                                                }}
                                                value={isFormSubmitted ? '' : phone}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form_box mb-30">
                                                <input type="email" name="email" id='email' placeholder="Correo corporativo*" onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    handleInputChange('email', e.target.value);
                                                }}
                                                value={isFormSubmitted ? '' : email}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form_box mb-30">
                                                <input type="text" name="companyName" id='companyName' placeholder="Nombre de la empresa*" onChange={(e) => {
                                                    setCompanyName(e.target.value);
                                                    handleInputChange('companyName', e.target.value);
                                                }}
                                                value={isFormSubmitted ? '' : companyName}/>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6">
                                            <div className="form_box mb-30">
                                                <input type="text" name="position" id='position' placeholder="Cargo" onChange={handleInputChange}/>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-6">
                                            <div className="form_box mb-30">
                                                <input type="text" name="web" id='companyURL' placeholder="URL de la empresa*" onChange={(e) => {
                                                    setCompanyURL(e.target.value);
                                                    handleInputChange('companyURL', e.target.value);
                                                }}
                                                value={isFormSubmitted ? '' : companyURL}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form_box mb-30">
                                            <textarea
                                                name="message"
                                                id="message"
                                                cols={30}
                                                rows={10}
                                                placeholder="Descripción del proyecto o funcionalidades*"
                                                className='form_box_input'
                                                value={isFormSubmitted ? '' : message}
                                                onChange={(e) => {
                                                    setMessage(e.target.value);
                                                    handleInputChange('message', e.target.value);
                                                }}
                                            ></textarea>
                                            </div>
                                            <div className="quote_btn text_center">
                                                <button className="btn" type="submit"><b>Enviar</b></button>
                                            </div>
                                        </div>
                                </div>
						</form>
						<p className="form-message"></p>
                        {isModalOpen && (
                            <Modal message='El formulario ha sido rellenado.' onClose={() => setIsModalOpen(false)} />
                        )}
                        {/* {showRequiredAlert && (
                            <div className="alert alert-danger">
                                Por favor, complete todos los campos requeridos.
                            </div>
                        )} */}
					</div>
				</div>
			</div>
		</div>
	</div>
    );
}

export default Contact;
function About() {
    return (
    <div id="about" className="about_area pt-90 pb-90">
		<div className="container">
			<div className="row">
				<div className="imagen_centrada col-lg-6 col-md-6 col-sm-12 col-xs-6">
					<div className="single_about_thumb mb-3">
						<div className="single_about_thumb_inner">
							<img src="assets/images/aboutWebFeynman_b.png" alt="" />
						</div>
					</div>
					<div className="single_about_shape">
						<div className="single_about_shape_thumb bounce-animate">
							<img src="assets/images/about-circle.png" alt="" />
						</div>
					</div>	
				</div>
				<div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
					<div className="section_title text_left mb-40 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>+10 AÑOS DE EXPERIENCIA</h6>
						</div>
						<div className="section_main_title">
							<h1>Somos <span>artesanos del código</span> y <span>vanguardistas</span> por defecto.</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
						<div className="section_content_text pt-4">
							<p>Nuestro respeto y admiración por la tecnología y sus posibilidades, hace que trabajemos con procesos perfectamente cuidados, en aras de conseguir la excelencia y los mejores resultados para nuestros clientes. </p>
							<p>Nuestro asesoramiento y desarrollo tecnológico ayuda a las empresas y organizaciones a aprovechar la tecnología y la innovación para dar forma a sus proyectos, llevar a cabo su transformación digital y reinventar sus negocios para crear un valor excepcional y sostenible. </p>
						</div>
					</div>
					{/* <div className="singel_about_left mb-30">
						<div className="singel_about_left_inner mb-3">
							<div className="about_icon mr-4">
								<div className="icon mt-3">
									<i className="flaticon-padlock"></i>
								</div>
							</div>
							<div className="singel-about-content">
								<h5>Plan estratégico integral</h5>
								<p>Nos encargamos de elaborar un plan integral basado en la información relevante sobre tu empresa.</p>
							</div>
						</div>
						<div className="singel_about_left_inner">
							<div className="about_icon mr-4">
								<div className="icon mt-3">
									<i className="flaticon-code"></i>
								</div>
							</div>
							<div className="singel-about-content">
								<h5>Proceso de ejecución de calidad</h5>
								<p>Nos encargamos de asesorarte y acompañarte durante todo el proceso de ejecución.</p>
							</div>
						</div>
					</div>	 */}
				</div>
				
			</div>
		</div>	
	</div>
    );
}

export default About;
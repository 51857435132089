function Home() {

    return (
    <div id="home" className="slider_list owl-carousel">
		<div className="slider_area d-flex align-items-center div-con-degradado" id="home">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="single_slider">
							<div className="slider_content">
                                    <div className="slider_text">
                                    <img style={{maxHeight: 100, paddingBottom: 10}} src="assets/images/refactor/feynmanSoftwareWhite.png" alt="" />
									<div className="slider_text_inner">
										{/* <h5>STRATEGIC & INNOVATION CONSULTANCY</h5> */}
										<h1>CONSULTORÍA TECNOLÓGICA </h1>
										<h6>Nuestro servicio de consultoría tecnológica ayuda a las empresas y organizaciones a utilizar la tecnología para impulsar la innovación y el crecimiento.</h6>
									</div>
									<div className="slider_button pt-5 d-flex">
										<div className="button">
										</div>
									</div>
									{/* <div className="slider-video">
										<div className="video-icon">
											<a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" href="https://youtu.be/BS4TUd7FJSg"><i className="fa fa-play"></i></a>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <div className="slider_area d-flex align-items-center slider2">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="single_slider">
							<div className="slider_content">
                                    <div className="slider_text">
                                    <img style={{maxHeight: 100, paddingBottom: 10}} className="pl-40" src="assets/images/refactor/onlyLetters.png" alt="" />
									<div className="slider_text_inner">
										<h5>STRATEGIC & INNOVATION CONSULTANCY</h5>
										<h1>APLICA LAS NUEVAS</h1>
										<h1>TECNOLOGÍAS EN TU EMPRESA</h1>
									</div>
									<div className="slider_button pt-5 d-flex">
										<div className="button">
										</div>
									</div>
									{/* <div className="slider-video">
										<div className="video-icon">
											<a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" href="https://youtu.be/BS4TUd7FJSg"><i className="fa fa-play"></i></a>
										</div>
									</div> 
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> */}
	</div>
    );
}

export default Home;
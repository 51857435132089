import React, { useEffect } from "react";

interface ModalProps {
    message: string
    onClose: () => void
}

function Modal({ message , onClose }: ModalProps) {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose()
        }, 5000)

        console.log('Modal abierto');
        
        return () => {
            clearTimeout(timer)
            console.log('Modal cerrado');
        }    
    }, [onClose])

    return (
        <div className="custom-modal">
            <div className="modal-content">
                <h3>Solicitud enviada</h3>
                <p>Revisa la bandeja de entrada de tu correo.</p>
            </div>
        </div>
    )
}

export default Modal;
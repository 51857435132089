function Services() {
    return (
    <div id="service" className="flipbox_area pt-85 pb-70 div-con-degradado-servicios" >
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="section_title text_center white mb-55">
						<div className="section_sub_title uppercase mb-3">
							<h6>SERVICIOS</h6>
						</div>
						<div className="section_main_title maximum_width_descriptions">
							<h1>¿En qué podemos ayudarte?</h1>
							<p>Nuestro equipo tecnológico de arquitectos y desarrolladores expertos en las nuevas tecnologías ayudan a impulsar cada negocio hacia la innovación y la mejora de productos y servicios globales. </p>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
					<div className="techno_flipbox mb-30">
						<div className="techno_flipbox_font">
								<div className="techno_flipbox_inner">
									<div className="techno_flipbox_icon">
										<div className="icon">
											<i className="flaticon-intelligent"></i>
										</div>
									</div>
									<div className="flipbox_title">
										<h3>Desarrollo de software, a medida</h3>
									</div>
									{/* <div className="flipbox_desc">
										<p>Asesoramiento 100% personalizado</p>
									</div> */}
								</div>
						</div>
						<div className="techno_flipbox_back " style={{ backgroundImage: 'url(assets/images/feature2-b.jpeg)'}}>
							<div className="techno_flipbox_inner">
								{/* <div className="flipbox_title">
									<h3>Asesoramiento</h3>
								</div> */}
								<div className="flipbox_desc">
                                        <ul>
                                            <div>Analizamos las necesidades de nuestros clientes y encontramos la solución más eficiente y robusta, creando aplicaciones útiles, sostenibles y escalables. </div>
                                            {/* <div>Preparación Founder Team</div> */}
                                    </ul>
								</div>
							</div>
						</div>
					</div>	
				</div>
				<div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
					<div className="techno_flipbox mb-30">
						<div className="techno_flipbox_font">
								<div className="techno_flipbox_inner">
									<div className="techno_flipbox_icon">
										<div className="icon">
											<i className="flaticon-padlock"></i>
										</div>
									</div>
									<div className="flipbox_title">
										<h3>Creación de nuevos productos</h3>
									</div>
									{/* <div className="flipbox_desc">
										<p>Adaptada a tu modelo de negocio</p>
									</div> */}
								</div>
						</div>
                            <div className="techno_flipbox_back " style={{ backgroundImage: 'url(assets/images/feature2-b.jpeg)'}}>
							<div className="techno_flipbox_inner">
								{/* <div className="flipbox_title">
									<h3>Consultoría 360º</h3>
								</div> */}
								<div className="flipbox_desc">
									<ul>
                                        <div>Nuestro equipo de ingeniería tiene la capacidad de crear productos desde cero, utilizando las tecnologías actuales como IoT, BigData, Blockchain, IA, ente otras. </div>
                                        {/* <div>Mapa de Riesgo</div>
                                        <div>Forecast Económico</div> */}
                                    </ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
					<div className="techno_flipbox mb-30">
						<div className="techno_flipbox_font">
								<div className="techno_flipbox_inner">
									<div className="techno_flipbox_icon">
										<div className="icon">
											<i className="flaticon-code"></i>
										</div>
									</div>
									<div className="flipbox_title">
										<h3>Asesoramiento y estrategia</h3>
                                    </div>
                                    {/* <div className="flipbox_desc">
										<p>Digitaliza tu negocio</p>
									</div> */}
								</div>
						</div>
						<div className="techno_flipbox_back " style={{ backgroundImage: 'url(assets/images/feature2-b.jpeg)'}}>
							<div className="techno_flipbox_inner">
								{/* <div className="flipbox_title">
									<h3>Servicios Digitales</h3>
                                    </div> */}
                                    <div className="flipbox_desc">
									<ul>
                                        <div>Nuestra experiencia trabajando con corporates y startups nos permite tener una visión amplia, en torno al desarrollo del software, y encontrar el equilibrio entre tecnología, calidad y eficiencia. </div>
                                        {/* <div>Asesoramiento creativo</div>
                                        <div>Desarrollo de Producto</div> */}
                                    </ul>
								</div>
							</div>
						</div>
					</div>	
				</div>
				<div className="offset-lg-2 col-lg-4 col-md-6 col-sm-12 col-xs-6">
					<div className="techno_flipbox mb-30">
						<div className="techno_flipbox_font">
								<div className="techno_flipbox_inner">
									<div className="techno_flipbox_icon">
										<div className="icon">
											<i className="flaticon-content-writing"></i>
										</div>
									</div>
									<div className="flipbox_title">
										<h3>Arquitectura sólida y escalable</h3>
                                    </div>
                                    {/* <div className="flipbox_desc">
										<p>Servicios de tokenización de activos</p>
									</div> */}
								</div>
						</div>
						<div className="techno_flipbox_back " style={{ backgroundImage: 'url(assets/images/feature2-b.jpeg)'}}>
							<div className="techno_flipbox_inner">
								{/* <div className="flipbox_title">
									<h3>Tokenización</h3>
								</div> */}
								<div className="flipbox_desc">
                                    <ul>
                                        <div>Aplicamos arquitecturas actualizadas y con un soporte de comunidad masivo, desarrollando así, plataformas con los frameworks y lenguajes de programación más punteros del sector. </div>
                                        {/* <div>Tokenomics</div>
                                        <div>Token economics</div> */}
                                    </ul>
								</div>
							</div>
						</div>
					</div>	
				</div>
				<div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
					<div className="techno_flipbox mb-30">
						<div className="techno_flipbox_font">
								<div className="techno_flipbox_inner">
									<div className="techno_flipbox_icon">
										<div className="icon">
											<i className="flaticon-bar-chart"></i>
										</div>
									</div>
									<div className="flipbox_title">
										<h3>Multi-stack y tecnologías vanguardistas</h3>
                                    </div>
                                    {/* <div className="flipbox_desc">
										<p>Creación, planificación y gestión.</p>
									</div> */}
								</div>
						</div>
						<div className="techno_flipbox_back " style={{ backgroundImage: 'url(assets/images/feature2-b.jpeg)'}}>
							<div className="techno_flipbox_inner">
								{/* <div className="flipbox_title">
									<h3>Crypto-Marketing</h3>
                                </div> */}
                                <div className="flipbox_desc">
                                    <ul>
                                        <div>Empleamos por defecto, los stacks MEAN (MongoDB, Express o Express.js, Angular.js y Node.js) y MERN (MongoDB, ExpressJS, ReactJS y NodeJS). No obstante, también empleamos el stack LAMP (Linux, Apache, MySQL y Phyton, PHP o Perl). </div>
                                        {/* <div>Comunidad</div>
                                        <div>SEO y SEM</div>
                                        <div>Web y landings</div> */}
                                    </ul>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>	
	</div>
    );
}

export default Services;
import React from 'react';
import './App.css';
import About from './sections/About/About';
import Contact from './sections/Contact/Contact';
// import Flipboxes from './sections/Flipboxes/Flipboxes';
import Header from './sections/Header/Header';
import Home from './sections/Home/Home';
import Services from './sections/Services/Services';
// import Testimonials from './sections/Testimonials/Testimonials';
import Working from './sections/Working/Working';
// import Team from './sections/Team/Team';
import Blogs from './sections/Blogs/Blogs';

function App() {
    return (
        <>
            <Header />
            <Home />
            {/* <Flipboxes /> */}
            <About />
            <Services />
            {/* <Team /> */}
            <Working />
            {/* <Testimonials /> */}
            <Blogs />
            <Contact />
        {/* <Features />
        <Brand /> */}
      </>
  );
}

export default App;

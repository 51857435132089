function Blogs() {

    return (
        <div className="blog_area pt-65 pb-55 slider-logos">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section_title text_center mb-60 mt-3">
                            <div className="section_sub_title uppercase mb-3">
                                <h6>CONFÍAN EN NOSOTROS</h6>
                            </div>
                            <div className="section_main_title">
                                <h1>Nuestro ecosistema</h1>
                            </div>
                            <div className="em_bar">
                                <div className="em_bar_bg"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/minteandome.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/lanzadera.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                        <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/mde.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/polygon.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/casper.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/oratrex.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/cultions.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/criptoservicios.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/beathard.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/novolabs.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/upbizor.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4">
                        <div className="single_blog mb-4">
                            <div className="single_blog_thumb">
                                <img src="assets/images/refactor/medios/cointelegraph.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
	    </div>
    );
}

export default Blogs;
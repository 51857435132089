function Header() {
    return (
        <>
        <div id="sticky-header" className="techno_nav_manu d-md-none d-lg-block d-sm-none d-none">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <div className="logo mt-3">
                        <a className="logo_img" href="index.html" title="techno">
                            <img style={{maxHeight: 50}} src="assets/images/refactor/logoNegro.png" alt="" />
                        </a>
                        <a className="main_sticky" href="index.html" title="techno">
                            <img style={{maxHeight: 50}} src="assets/images/refactor/logoBlanco.png" alt="astute" />
                        </a>
                    </div>
                </div>
                <div className="col-md-9">
                    <nav className="techno_menu">
                        <ul className="nav_scroll">
                            <li><a href="#home">Inicio</a></li>
                            <li><a href="#service">Servicios</a></li>
                            {/* <li><a href="#team">Quiénes somos</a></li> */}
                            <li><a href="#working">Cómo trabajamos</a></li>
                            <li><a href="#contact">Contacto</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}

export default Header;